import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

import BlogCaption from './BlogCaptionOnScreenSize';
import BoldTitle from './BoldTitle';

function BlogHighlights({ title, count, featuredBlogs }) {
  return (
    <div className="flex justify-center w-full">
      <div className="w-full max-w-6xl">
        <BoldTitle className="mt-16 mb-8 md:mt-24 md:mb-12 md:text-center">
          {title}
        </BoldTitle>
        <div
          className={`md:grid md:grid-cols-${count} mt-10 gap-4 lg:gap-11 mb-8 md:mb-24 px-6 xl:px-0`}
        >
          {featuredBlogs.slice(0, count).map((item) => (
            <Link to={`/blog/${item.slug}/`} key={item.slug}>
              <div className="m-6 transition duration-500 ease-in-out md:w-full rounded-2xl hover:shadow-2xl md:m-0">
                <GatsbyImage
                  className="bg-cover rounded-t-2xl"
                  image={item.featuredImage.node.gatsbyImage}
                  alt={item.featuredImage.node.altText}
                />
                <BlogCaption
                  title={item.title}
                  date={item.date}
                  categories={item.categories}
                />
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

BlogHighlights.propTypes = {
  title: PropTypes.string,
  count: PropTypes.number,
  featuredBlogs: PropTypes.array.isRequired,
};

BlogHighlights.defaultProps = {
  title: 'From Our Blog',
  count: 3,
};

export default BlogHighlights;
